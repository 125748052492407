export default [
  'name',
  'address',
  'city',
  'state',
  'country',
  'zip',
  'status',
  { key: 'user', label: 'Owner', sorter: false },
  { key: 'action', label: '' }
]
